import "./footer.css";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import Button from "./Button";
import {
  DataHubServiceDesk,
  DMPUrl,
  helpCenterUrl,
  MDRUrl,
} from "../assets/data/urls";
import { HashLink } from 'react-router-hash-link';
import MUMC from "../assets/img/mumc_light.svg";
import UM from "../assets/img/UM-logo-white.png";
import DH from "../assets/img/blue.png";

export default function Footer() {
  return (
    <>
      <div className="footer__content">
        <div className="footer__header">
        <div className="footer__logo_container">
                <img id="UM" src={UM} alt="UM" />
                <img id="DH" src={DH} alt="MDR" />
                <img id="MUMC" src={MUMC} alt="MUMC" />
              </div>
              <h4>
                <a href={helpCenterUrl + "/support"}>
                  <Button
                    className="primary"
                    text="Have a question, remark or request? Contact us"
                  />
                </a>
              </h4>
        </div>

        <div className="footer__body flex">
          <div className="footer__body_item">
            <h5>Address</h5>
            <p>
              DataHub Maastricht <br />
              Paul-Henri Spaaklaan 1 (3rd floor) <br />
              6229 EN Maastricht <br />
              Nederland
            </p>
          </div>
          <div className="footer__body_item">
            <h5>Contact details</h5>
            <a href="tel:0031433872844">
              <p>0031 43 3872844</p>
            </a>
            <a href="mailto:datahub@maastrichtuniversity.nl">
              <p>datahub@maastrichtuniversity.nl</p>
            </a>
            <a href="mailto:datahub@mumc.nl">
              <p>datahub@mumc.nl</p>
            </a>
          </div>
          <div className="footer__body_item">
            <h5>Resources</h5>
            <a href="/privacy-statement">
              <p>Privacy policy</p>
            </a>
            <a href="/terms-of-service">
              <p>Terms of service</p>
            </a>
            <a href={helpCenterUrl}>
              <p>Help center</p>
            </a>
            <a href={DataHubServiceDesk}>
              <p>Service desk</p>
            </a>
          </div>
          <div className="footer__body_item">
            <h5>Tools</h5>
            <a href={MDRUrl}>
              <p>Maastricht Data Repository</p>
            </a>
            <a href={DMPUrl}>
              <p>DMP Maastricht</p>
            </a>
            <HashLink smooth to="/#home">
              <p>DataHub</p>
            </HashLink>
          </div>
        </div>
      </div>

      <hr className="footer__border" />
      <div className="footer__footer">
        © 2023, made by DataHub <br />
        <a href="https://www.linkedin.com/company/datahubmaastricht/">
          <AiFillLinkedin className="linkedin" />
        </a>
        <a href="https://github.com/orgs/MaastrichtUniversity/">
          <AiFillGithub className="github" />
        </a>
      </div>
    </>
  );
}
