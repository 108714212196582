import React from "react";
import "../App.css";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Layout() {
  return (
    <div id="home" className="main__wrapper">
      <Navbar />
      <Outlet />
      <section id="contact">
        <Footer />
      </section>
    </div>
  );
}
