import React from "react";
import styled from "styled-components";

const Title = ({ title }) => {
  return (
    <>
      <PrimaryTitle>{title}</PrimaryTitle>
    </>
  );
};

export default Title;

const PrimaryTitle = styled.h2`
  font-size: 28px;
  text-align: left;
  margin-bottom: 1rem;
`;
