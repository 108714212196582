import Logo from "../assets/img/blue.png";
import "./navbar.css";
import { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { HashLink } from "react-router-hash-link";
import { motion, useScroll } from "framer-motion";
import { Link } from "react-router-dom";

export default function Navbar() {
  const { scrollYProgress } = useScroll();
  /* Handling style changes on scroll */
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navbar = document.getElementById("navbar");
    const navbarHeight = scrollPosition > 80 ? "70px" : "80px";
    navbar.style.setProperty("--navbar-height", navbarHeight);
  }, [scrollPosition]);

  /* State for transitioning between hidden/visible mobile dropdown */
  const [dropdownMobile, setDropdownMobile] = useState(false);
  const handleClick = () => {
    setDropdownMobile(!dropdownMobile);
  };

  /* Styling objects for react-transition library for smooth transition between states */
  const defaultStyles = {
    visibility: "hidden",
    transition: `all 0.3s ease`,
    opacity: 0,
  };
  const transitionStyles = {
    entering: { opacity: 1, visibility: "visible" },
    entered: { opacity: 1, visibility: "visible" },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <div id="navbar">
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <div className="navbar__container flex">
        <HashLink smooth to="/#home">
          <div
            className="navbar__logo"
            style={{ backgroundImage: `url(${Logo})` }}
          ></div>
        </HashLink>

        {/* MOBILE NAVIGATION */}
        <div className="navbar__hamburger flex" onClick={handleClick}>
          {dropdownMobile ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>

        <Transition in={dropdownMobile} timeout={300}>
          {(state) => (
            <div
              className="navbar__dropdown_mobile"
              style={{
                ...defaultStyles,
                ...transitionStyles[state],
              }}
            >
              <div className="navbar__internal_links flex">
                <Link to="/blog">Blog</Link>
                <HashLink smooth to="/#services">
                  Services
                </HashLink>
                <HashLink smooth to="/#about">
                  About
                </HashLink>
                <HashLink smooth to="/#contact">
                  Contact
                </HashLink>
              </div>
            </div>
          )}
        </Transition>

        {/* DESKTOP NAVIGATION  */}

        <div className="navbar__desktop">
          <div className="navbar__internal_links">
            <Link to="/blog">Blog</Link>
            <HashLink smooth to="/#services">
              Services
            </HashLink>
            <HashLink smooth to="/#about">
              About
            </HashLink>
            <HashLink smooth to="/#contact">
              Contact
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}
