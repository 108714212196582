import Landing from "./pages/Landing";
import Layout from "./pages/Layout";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import RenderPDF from "./pages/RenderPDF";
import Blog from "./pages/Blog";
import BlogPage from "./pages/BlogPage";
import ScrollToTop from "./components/blog/ScrollToTop";

function App() {
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "DataHub - Homepage";
      case "/blog":
        return "DataHub - Blog";
      default:
        if (location.pathname.match(/\/blog\/(\d+)/)) {
          return "DataHub - Blog page";
        } else {
          return "DataHub - Homepage";
        }
    }
  };
  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <ScrollToTop />
      <main>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:pageId" element={<BlogPage />} />
          </Route>
          <Route
            path="/privacy-statement/"
            element={<RenderPDF name={"privacy-statement"} />}
          />
          <Route
            path="/terms-of-service/"
            element={<RenderPDF name={"terms-of-service"} />}
          />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </main>
    </>
  );
}

export default App;
