import React from "react";
import FetchArticles from "../components/blog/FetchArticles";

export default function Blog() {
  return (
    <section>
      <div
        className="section__content"
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
      >
        <h3>
          <strong>DataHub</strong> news & updates
        </h3>
        <FetchArticles />
      </div>
    </section>
  );
}
