import React from "react";
import "./card.css";
import { FaExternalLinkAlt } from "react-icons/fa";
export default function Card(props) {
  const icon = props.icon;

  return (
    <div className="card" id={props.id} data-aos="fade-right">
      <div className="card__header">
        <img src={icon} alt="Logo" />
      </div>
      <div className="card__main">
        <h4 className="card__title">{props.title}</h4>
        <div className="card__description">
          <p>{props.description}</p>
        </div>
        <div className="card__footer">
          <hr className="card__footer_border" />
          {props.link ? (
            <a href={props.link} data-hover={props.linkTitle}>
              <FaExternalLinkAlt />
              {props.linkTitle}
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
