import "./cardCommunity.css";

export default function CardCommunity(props) {
  return (
    <div className="community__card">
      <div className="comunnity__card_img_container flex">
        <div className="community__card_img_border">
          <div
            className={`community__card_img ${props.className}`}
            style={{ backgroundImage: `url(${props.img})` }}
          ></div>
        </div>
      </div>

      <div className="community__card_body">
        <p>
          <span translate="no">{props.name + " " + props.surname}</span>
        </p>
        <p style={{ fontWeight: "bolder" }}>{props.role}</p>
      </div>
    </div>
  );
}
