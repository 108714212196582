import Kristine from "../img/community/Kristine.png";
import Ricardo from "../img/community/Ricardo.jpeg";
import Mirjam from "../img/community/Mirjam.JPG";
import Dean from "../img/community/Dean.png";
import Jonathan from "../img/community/Jonathan.png";
import Olav from "../img/community/Olav.JPG";
import Kelly from "../img/community/KellySchmitz.jpg";
import Pascal from "../img/community/Pascal.png";
import Daniel from "../img/community/Daniel.png";
import Maria from "../img/community/Maria.png";
import Helene from "../img/community/Helene.JPG";
import Kim from "../img/community/Kim.JPG";

/* Add className: image-small property to adjust the zoom of images */

const COMMUNITY = [
  {
    key: 1,
    name: "Kristine",
    surname: "Gusta",
    role: "Data Engineer | Agile Scrum Master",
    img: Kristine,
  },
  {
    key: 2,
    name: "Ricardo",
    surname: "Juhasz",
    role: "DevOps Engineer",
    img: Ricardo,
  },
  {
    key: 3,
    name: "Hélène",
    surname: "Kabbech",
    role: "Data Engineer",
    img: Helene,
    className: "image-small",
  },
  {
    key: 4,
    name: "Mirjam",
    surname: "Kamps",
    role: "Communication Officer | Agile Scrum Master",
    img: Mirjam,
    className: "image-small",
  },
  {
    key: 5,
    name: "Dean",
    surname: "Linssen",
    role: "Lead DevOps Engineer",
    img: Dean,
  },
  {
    key: 6,
    name: "Jonathan",
    surname: "Melius",
    role: "Data Engineer",
    img: Jonathan,
  },
  {
    key: 7,
    name: "Olav",
    surname: "Palmen",
    role: "Data Steward | Agile Product Owner",
    img: Olav,
    className: "image-small",
  },
  {
    key: 8,
    name: "Kelly",
    surname: "Schmitz",
    role: "Secretary",
    img: Kelly,
    className: "image-small",
  },
  {
    key: 9,
    name: "Pascal",
    surname: "Suppers",
    role: "Managing Director",
    img: Pascal,
  },
  {
    key: 10,
    name: "Daniël",
    surname: "Theunissen",
    role: "Data Engineer | Agile Product Owner",
    img: Daniel,
  },
  {
    key: 11,
    name: "Maria",
    surname: "Vivas - Romero",
    role: "Data Steward | RDM Trainings Specialist",
    img: Maria,
    className: "image-small",
  },
  {
    key: 12,
    name: "Kim",
    surname: "Lamers",
    role: "Data Engineer",
    img: Kim,
    className: "image-small",
  },
];

/* Sort the team member array by surname alphabetically */
COMMUNITY.sort((a, b) => {
  const nameA = a.surname;
  const nameB = b.surname;

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
});

export default COMMUNITY;
