import PrivacyPDF from "../assets/DataHub_privacy_statement.pdf";
import TermsOfServicePDF from "../assets/DataHub_Terms_of_Services_20221231.pdf";

export default function RenderPDF(props) {
  let PDFFile;
  switch (props.name) {
    case "privacy-statement":
      PDFFile = PrivacyPDF;
      break;
    case "terms-of-service":
      PDFFile = TermsOfServicePDF;
      break;
    default:
      PDFFile = PrivacyPDF;
  }

  return (
    <object
      data={PDFFile}
      type="application/pdf"
      width="100%"
      style={{ height: "calc(100vh - 43px)" }}
      aria-label="This object displays an PDF file"
    />
  );
}
