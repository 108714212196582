/* Counting effect library */
import PureCounter from "@srexi/purecounterjs";
import { useEffect, useRef } from "react";
import "./fact.css";

export default function Fact(props) {
  //Pure counter span
  const counter = useRef(null);

  useEffect(() => {
    new PureCounter();
  }, []);

  return (
    <div className="fact">
      <div className="fact__header">
        <span
          data-purecounter-start="0"
          data-purecounter-end={props.endNumber}
          className="purecounter"
          ref={counter}
        >
          0
        </span>
        +
      </div>
      <p>{props.description}</p>
    </div>
  );
}
