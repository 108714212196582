import React from "react";
import "./blog-page.css";
import FetchArticle from "../components/blog/FetchArticle";

export default function Blog() {
  return (
    <section>
      <div
        className="section__content"
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
      >
        <FetchArticle />
      </div>
    </section>
  );
}
