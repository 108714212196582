import Spacer from '../assets/svg/spacer.svg'
import UM from '../assets/img/logo-um.png'
import MUMC from '../assets/img/logo-mumc.png'
import './hero.css'

export default function Hero() {
  return (

      <header id="hero">
        <div className="hero">
          <div className="hero__title flex">
            <div className="hero__title_small">
              <h1>DataHub Maastricht</h1>
              <h2>
                Linking community and technology to enable <strong>FAIR</strong> data
              </h2>
              <a href='#services'><button id="hero__button__read_more" className="hero__button">Read more</button></a>
            </div>
          </div>
          <div
            className="spacer"
            style={{ backgroundImage: `url(${Spacer})` }}
          ></div>
        </div>
        <div className="hero__logos flex">
          <div className="hero__logo" style={{ backgroundImage: `url(${UM})` }}></div>
          <div className="hero__logo" style={{ backgroundImage: `url(${MUMC})` }}></div>
        </div>
      </header>
 

  );
}
