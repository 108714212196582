import "./video.css";

export default function Video() {
  return (
    <div className="fair-principles_wrapper">
      <div className="fair-principles_title">
        <h3>
          Store your research data securely at <strong>DataHub</strong>
        </h3>
        <p>
          Open Science ambassador Dennie Hebels about data storage at DataHub’s
          Maastricht Data Repository: “Safely store your data for the long term
          without you having to worry about it”.
        </p>
      </div>
      <div className="video__container">
        <iframe
          className="responsive-iframe"
          src="https://www.youtube.com/embed/PGTB7vKKiTA"
          title="YouTube video player"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
